<template>
    <el-dialog
      title="录音"
      :modal-append-to-body="false" append-to-body
      :visible.sync="visible">
      <div style="padding: 20px;">
 
 <div style="font-size:14px">
   <h3>录音时长：{{ recorder && recorder.duration.toFixed(4) }}</h3>
   <br />
   <el-button type="primary" @click="handleStart">开始录音</el-button>
   <el-button type="info" @click="handlePause">暂停录音</el-button>
   <el-button type="success" @click="handleResume">继续录音</el-button>
   <el-button type="warning" @click="handleStop">停止录音</el-button>
   <br />
   <br />
   <h3>
     播放时长：{{
       recorder &&
         (playTime > recorder.duration
           ? recorder.duration.toFixed(4)
           : playTime.toFixed(4))
     }}
   </h3>
   <br />
   <el-button type="primary" @click="handlePlay">播放录音</el-button>
   <el-button type="info" @click="handlePausePlay">暂停播放</el-button>
   <el-button type="success" @click="handleResumePlay">继续播放</el-button>
   <el-button type="warning" @click="handleStopPlay">停止播放</el-button>
   <el-button type="error" @click="handleDestroy">销毁录音</el-button>
   <el-button type="primary" @click="uploadRecord">上传</el-button>
   <el-button type="primary" @click="deleteRecord">删除上传录音</el-button>
 </div>
      </div>
      <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
    </el-dialog>
  </template>


<script>
import Recorder from 'js-audio-recorder'
import {uploadFile,deleteFile} from '@/api/sys/upload'
export default {
  data() {
    return {
      visible: false,
      isOnSubmit: false,
      dataForm:{
        id : ''
      },
      recorder: null,
      playTime: 0,
      timer: null,
      src: null,
      recordFiles:'',
    }
  },
  created() {
    this.recorder = new Recorder()
  },
  methods: {
    init(id){
      this.visible = true
      this.dataForm.id = id
      this.$nextTick(() => {
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/dataflow/olfmajorwarining/recordInfo/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.recordFiles = data.data.recordFilePath
              }
            })
          }
        })
    },
    // 开始录音
    handleStart() {
      this.recorder = new Recorder()
      Recorder.getPermission().then(() => {
        console.log('开始录音')
        this.recorder.start() // 开始录音
      }, (error) => {
        this.$message({
          message: '请先允许该网页使用麦克风',
          type: 'info'
        })
        console.log(`${error.name} : ${error.message}`)
      })
    },
    handlePause() {
      console.log('暂停录音')
      this.recorder.pause() // 暂停录音
    },
    handleResume() {
      console.log('恢复录音')
      this.recorder.resume() // 恢复录音
    },
    handleStop() {
      console.log('停止录音')
      this.recorder.stop() // 停止录音
    },
    handlePlay() {
      console.log('播放录音')
      console.log(this.recorder)
      this.recorder.play() // 播放录音
 
      // 播放时长
      this.timer = setInterval(() => {
        try {
          this.playTime = this.recorder.getPlayTime()
        } catch (error) {
          this.timer = null
        }
      }, 100)
    },
    handlePausePlay() {
      console.log('暂停播放')
      this.recorder.pausePlay() // 暂停播放
 
      // 播放时长
      this.playTime = this.recorder.getPlayTime()
      this.time = null
    },
    handleResumePlay() {
      console.log('恢复播放')
      this.recorder.resumePlay() // 恢复播放
 
      // 播放时长
      this.timer = setInterval(() => {
        try {
          this.playTime = this.recorder.getPlayTime()
        } catch (error) {
          this.timer = null
        }
      }, 100)
    },
    handleStopPlay() {
      console.log('停止播放')
      this.recorder.stopPlay() // 停止播放
 
      // 播放时长
      this.playTime = this.recorder.getPlayTime()
      this.timer = null
    },
    handleDestroy() {
      console.log('销毁实例')
      this.recorder.destroy() // 毁实例
      this.timer = null
    },
    uploadRecord() {
      if (this.recorder == null || this.recorder.duration === 0) {
        this.$message({
          message: '请先录音',
          type: 'error'
        })
        return false
      }
      this.recorder.pause() // 暂停录音
      this.timer = null
      console.log('上传录音')// 上传录音
 
      const formData = new FormData()
      const blob = this.recorder.getWAVBlob()// 获取wav格式音频数据
      // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
      const newbolb = new Blob([blob], { type: 'audio/wav' })
      // //此处将mav转为mp3格式
      // const mp3Blob = this.convertToMp3(this.recorder.getWAV());
      // const fileOfBlob = new File([mp3Blob], new Date().getTime() + '.mp3')
      const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
      formData.append('file', fileOfBlob)
      // const url = window.URL.createObjectURL(fileOfBlob)
      // this.src = url
      // const axios = require('axios')
      // axios.post(url, formData).then(res => {
      //   console.log(res.data.data[0].url)
      // })
      uploadFile(formData).then(({data}) => {
          if (data && data.data && data.code === 0) {
            this.$message("上传成功");
            this.recordFiles = data.data.url;
          }else{
            this.$message.error(data.msg ? data.msg : "上传失败");
          }
        })
    },
    deleteRecord(){
      let id = this.recordFiles
      if (id != null && id != "") {
         // 后台删除 
         deleteFile(id).then(({ data }) => {
          if (data && data.code === 0) {
            this.$message("删除成功");
          }
        });
      }
    },
    dataFormSubmit () {
      let id = this.recordFiles
          if (id) {
          	this.isOnSubmit = true
            this.$http({
              url: this.$http.adornUrl(`/dataflow/olfmajorwarining/uploadRecord`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id,
                'recordFilePath':id,
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 500 
                })
                this.visible = false
                this.$emit('refreshDataList')
              } else {
                this.$message.error(data.msg)
              }
            }).finally(()=>{
            	this.isOnSubmit = false
            })
          }
      },
  }
}
</script>
<style lang='less' scoped>
 
</style>